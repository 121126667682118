body{
  width: 100%;
  height: 100%;
}

*{
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}

h1, h2, h3, h4, h5{
  font-family: 'Great Vibes';
  font-weight: bold;    
}

#background-scena{
  background-image: url(../public/image/f2.png); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
} 

#PageNotFound{  
  background-color: rgb(88, 22, 72);
}

