/*------------------ STYLE MENU ------------------*/

#navbar, .dropdown-menu{
    background-color:rgb(54 14 44) !important;
    padding:0 !important;
    left: 0 !important;
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    /*transition: top 0.5s;*/
    z-index: 3 !important;
}

#navbar a{
    float: left;
    color: white;
    text-align: center;
    padding: 15px;
    text-decoration: none;
}
    
#navbar .login {
    float: right;
}
  
#navbar .cad{
    float: right;
}
    
#navbar a:hover, .dropdown-menu li:hover{
    background-color: #812a6b;
    color: #2e0825;
}

#navbar-dropdown, .dropdown-menu{
    display: none;
}

.dropdown-menu a{
    display: flex;
    width: 100%;    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
    width: 100%;
    cursor: pointer;
    font-weight: bold;    
}

.time-content2{
    display: none;
}

.time-content{
    display: block;
}

.login, .cad, .sobreTop, .inicio{
    display: block;
}
