/*------------------ STYLE FOOTER ------------------*/

.contato{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.contato, .contato p{
    margin: 5px auto;
}

.contato p{
    font-size: 14px;
}

.contato a{
    margin: 6px;
    color: rgb(255, 255, 255); 
    text-decoration: none;
    font-size: 14px;  
    font-weight: 600;
}

.contato a:hover{    
    color: rgb(88, 22, 72); 
    text-shadow:  0px 0px 3px rgb(255, 255, 255);
}