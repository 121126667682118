#startDemo{
    height: 40px;
    width: 150px;
    margin: auto;
    position: relative;
    letter-spacing: 0.2em;
	font-weight: 600;
	font-size: 25px;
	text-align: center;
	color: rgb(255,255,255);
    cursor: pointer;    
    outline: 3px solid;
    outline-color: rgb(88, 22, 72);
    outline-offset: 10px;
    transition: all 600ms cubic-bezier(0.2, 0, 0, 0.8);
    top: 250px;
    display: block;
}

#startDemo:hover{
    color: rgba(88, 22, 72, 0.8);
    outline-color: rgba(71, 126, 232, 0);
    outline-offset: 300px;
    text-shadow: 0px 0px 5px #ffffff;
}

#startDemo:active{
    opacity: 0;  
    text-shadow: 0px 0px 30px #ffffff;
}

.button__text {
    z-index: 1;
    font-size: 16px;
    margin-right: -18px;
    color: rgb(255,255,255);
    letter-spacing: 0.05em;
    width: 40px;
    opacity: 0;
    transition: opacity 987ms;
}
  
.button__wrapper {
    position: relative;
    display: flex;
    align-items: center;
}
  
.button__border-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgba(90, 20, 72);
    transition: transform 987ms;
}
  
.button__mask-circle {
    width: 50px;
    height: 50px;
    position: absolute;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: circle(25px);
    transition: clip-path 987ms;
}
  
.button__small-circle{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: translateX(60px);
    background-color: rgb(88, 22, 72);
}

.content-buttonBack{
    width: 130px;
    position: relative;
    float: right;
    right: 25px;
}

#content-tutorial{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.8) 0%;
    z-index: 9999;    
    display: block;
}    

.chibi-sensei{
    height: 400px;    
    position: relative;
}

#tutorial{
    height: 400px;
    width: 600px;
    background-color: rgb(255,255,255);
    outline: rgb(164, 154, 49) double 4px;
    outline-offset: 25px;
    border-radius: 30%;
    position: relative;
    margin: auto;
    top: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.tutorial{
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 50px;
    padding: 0 28px;
}

.tutorial p{
    color: rgb(164, 154, 49);
    text-align: justify;
}

#btnNextTut{
    font-size: 34px;
    font-weight: bold;
    text-align: end;
    cursor: pointer;
    color: rgb(164, 154, 49);   
    position: absolute;
     
}

#btnNextTut:hover{
    text-shadow: 0 0 10px rgb(164, 154, 49);
    color: rgb(255, 255, 255);
}

#imgTutorial{
    width: 150px;
    display: none;
}

#imgTutorial2{
    width: 150px;
    display: none;
    position: absolute;
    top: 100px;
    right: 68px;
}

#btnNextTut, #imgTutorial, #imgTutorial2, .tutorial p{
    margin: 10px 5px;
    bottom: 0;
}

/******************************DEMO******************************/
.box-eps{
    width: 850px;
    height: 800px;
    margin: 20px auto 40px;     
}

.content-eps{
    width: 800px;
    height: 600px;
    margin: 20px auto 40px;    
    position: relative;
    background-color: rgb(72, 68, 71);
    overflow: hidden;
}

#view-objective{
    top: 0;
    display: grid;
    width: 50px; 
    background-color: rgba(88, 22, 72, 34%);   
    height: auto;
    position: absolute;
    border-bottom-right-radius: 15px;
    z-index: 1;
}

#btn-vo{
    font-size: 24px;
    font-weight: bold;
    color: rgb(255,255,255);
    -webkit-transition: text-shadow 0.5s ease-in-out;
    -moz-transition: text-shadow 0.5s ease-in-out;
    -o-transition: text-shadow 0.5s ease-in-out;
    -ms-transition: text-shadow 0.5s ease-in-out;
    transition: text-shadow 0.5s ease-in-out;
    text-shadow: 0px 0px 5px rgb(255,255,255);
    width: 50px;
    cursor: pointer;       
}

#btn-vo.shadowActive{
    text-shadow: 4px 0px 2px rgb(255,255,255);
}

.objectives{
    display:none;
    width: 88%;
    height: 25px;
    margin: 2px auto;
    position: relative;
    text-align: justify;
    color:rgb(255, 255, 255);
    font-weight: bold;
}

.demo-text{
    position: relative;
    color: rgb(255,255,255);
    padding: 14px;
    margin: auto;
    font-weight: bold;
    display: none;
    font-family: 'Raleway', sans-serif;
    min-height: 150px;
}

.dt1{
    width: 500px;
    height: auto;
    top: 200px;
    text-align: center;
    font-size: 24px;
    padding-top: 20px;
}

.dt2{
    width: 660px;
    height: auto;
    text-align: justify;
    top: 60px; 
    font-size: 20px;
    background-image: linear-gradient(180deg, rgba(88, 22, 71, 51%), rgba(248, 176, 206, 0.4));
}

.select-option{    
    bottom: 0;
    display: grid;
    width: 100%;    
    position: absolute;
    display: none;
}

.option{
    background-color: rgba(88, 22, 71, 0.4);
    width: 100%; 
    height: 42px;    
    padding: 8px 14px;
    color: rgb(255,255,255);
    text-align: justify;
    cursor: pointer;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

.option:hover{
    background-color: rgba(248, 176, 206, 0.4);
}

.eye-open{
    display: none;
    background-image: url(../image/scene/Demo3.png); 
    background-position: top;
    background-size: cover;
    background-position-x: inherit;
    height:500px;
    width:500px; 
    position:absolute;
    transform:translate(30%, 10%) rotate(45deg);
    border-top-left-radius: 500px;
    border-top-right-radius: 2%;
    border-bottom-right-radius: 500px;
    border-bottom-left-radius: 2%;
    overflow:hidden;    
}

@-webkit-keyframes blur{
    0% { border-top-left-radius: 500px; border-bottom-right-radius: 500px; filter: blur(22px) brightness(200%); }
    15% { border-top-left-radius: 475px; border-bottom-right-radius: 475px; filter: blur(19.2px) brightness(165%);}
    25%{ border-top-left-radius: 500px; border-bottom-right-radius: 500px; filter: blur(16.5px) brightness(160%); }
    50% { border-top-left-radius: 425px; border-bottom-right-radius: 425px; filter: blur(11px) brightness(135%); }
    75% { border-top-left-radius: 475px; border-bottom-right-radius: 475px; filter: blur(5.5px) brightness(110%); }
    100% { border-top-left-radius: 350px; border-bottom-right-radius: 350px; filter: blur(0px) brightness(100%); }
}

@-webkit-keyframes shadowBackground{
    0% { background-color: rgba(0, 0, 0, 0); -webkit-transform: scale(0); transform: scale(0);}
    50% { background-color: rgba(0, 0, 0, 0.5); -webkit-transform: scale(0.5); transform: scale(0.5);}
    100% { background-color: rgba(0, 0, 0, 1); -webkit-transform: scale(1); transform: scale(1);}
}

@keyframes blur{
    0% { border-top-left-radius: 500px; border-bottom-right-radius: 500px; filter: blur(22px) brightness(200%); }
    15% { border-top-left-radius: 475px; border-bottom-right-radius: 475px; filter: blur(19.2px) brightness(165%);}
    25%{ border-top-left-radius: 500px; border-bottom-right-radius: 500px; filter: blur(16.5px) brightness(160%); }
    50% { border-top-left-radius: 425px; border-bottom-right-radius: 425px; filter: blur(11px) brightness(135%); }
    75% { border-top-left-radius: 475px; border-bottom-right-radius: 475px; filter: blur(5.5px) brightness(110%); }
    100% { border-top-left-radius: 350px; border-bottom-right-radius: 350px; filter: blur(0px) brightness(100%); }
}

@keyframes shadowBackground{
    0% { background-color: rgba(0, 0, 0, 0); -webkit-transform: scale(0); transform: scale(0);}
    50% { background-color: rgba(0, 0, 0, 0.5); -webkit-transform: scale(0.5); transform: scale(0.5);}
    100% { background-color: rgba(0, 0, 0, 1); -webkit-transform: scale(1); transform: scale(1);}
}


/*************** DEMO - CSS de Passos (marcador de mapa) ***************/
[mapa]::after {
    content: attr(mapa);
    display: none;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    font-size: 14px;	
	color: rgb(88, 22, 72);
	border-radius: 10px;
    font-weight: bold;
    top:-20px;
    right: 10px;
}

[mapa]:hover::after {
display: block;
}

.pass-walk{
    display: none;
    position: absolute;
    background-image: url(../image/walk-icon.png);
    background-size: contain;
    width: 40px;
    height: 40px;        
}

.pass-walk:hover{
    background-image: url(../image/walk-icon2.png);
    cursor: pointer;
}

/***********************************************************************/
.caracter{
    background-image: url(../image/sombra.png);
    background-size: 800px 1100px;
    position: relative;
    width: 800px;
    height: 600px;
    margin: auto;
    -webkit-filter: drop-shadow( 0px 0px 16px #292b36);
    filter: drop-shadow( 0px 0px 16px #292b36);
    display: none;
}

.caracter-dialog{
    border-radius: 40% 70% 40% 70%;
    background-color: rgba(255, 255, 255, 77%);
    position: absolute;
    font-size: 13px;
    font-weight: bold;
    top: 50px;
    left: 110px;
    width: 200px;  
    height: 200px;
    padding: 22px 10px;
    display: none;
}

.text-dialog-pj{
    position: absolute;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    top: 65px;
    left: 15px;  
    padding: 10px;  
} 

#endDemo{
    width: 436px;
    height: 250px;
    position: relative;
    top: 90px;
    margin: 60px auto;
    background-color: rgb(255, 255, 255, 0.17);    
    padding: 25px;
    outline: groove 2px rgb(255, 255, 255);
    outline-offset: 12px;
    display: none;
}

#endDemo p{
    color: rgb(255, 255, 255);
    font-size: 36px;
    text-align: center;
    font-family: 'Great Vibes';    
}

#endDemo #startDemo{
    width: 230px;
    top: 14px;
}

#endDemo > #startDemo p{
    font-size: 24px;
}


/********************** SVG STYLE **********************/
#stop1, #stop2{
    stop-color: rgba(255, 255, 255, 0.77);
}

path{
    stroke-width: 0;
    transition: all 0.3s ease 0s;
}

