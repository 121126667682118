body{
    width: 100%;
    height: 100%;
}

*{
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
}

h1, h2, h3, h4, h5{
    font-family: 'Great Vibes';
    font-weight: bold;    
}

legend{
    width: 30%;
    float: none;
    font-weight: bold;
    font-size: 28px;
    color: rgb(88, 22, 72);
    
}

fieldset{
    border:2px groove rgba(88, 22, 72, 0.72); 
    border-radius: 20px;  
    position: relative;
    margin: auto; 
    width: 550px;  
    top: 100px;
    background-color: rgba(0, 0, 0, 0.205);
} 

.pageNotFound{
    margin: auto;
    min-width: 400px;
    max-width: 600px;
    padding: 10px; 
    position: relative; 
    top: 150px;
  }
  
.pageNotFound img{
    min-width: 400px;
    max-width: 600px;
}

.menu{
    position: relative;
    background-color: rgba(88, 22, 72, 0.72);
    height: 90px;
    padding: 5px;
    width: 800px;
    margin: auto;
    border-bottom-right-radius: 70px;
    border-top-left-radius: 70px;
    top: 100px;
    border:2px groove rgb(88, 22, 72, 0.72); 
    
}

.menu-2{    
    height: 100px;
    width: 100%;
    margin: 20px auto auto;
    text-align: center;
}

.menu-2 a{
    position: relative;
    top: 55px;
    margin: auto 5px;
    text-decoration: none;
    font-size: 19px;
    color: rgb(88, 22, 72); 
    font-weight: bold;   
}

.menu-2 a:hover{
    color: rgb(255, 255, 255);
    text-shadow:  2px 2px 6px rgb(88, 22, 72);
}

a{
    text-decoration: none;
}

.box{
    width: 100%;
    min-height: 1000px;
    padding: 10px;
    text-align: center;
    position: relative;        
}

.box:nth-child(2){
    margin-top: 56px;
}

.borderGrad{
    border: 10px solid; 
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(193,168,76,1), rgba(0, 0, 0, 0)) 1 1 100%;
}

.borderGrad2 {
    border: 8px solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(88, 22, 71,1), rgba(0, 0, 0, 0)) 1 1 100%;
}

.borderGrad3 {
    border: 4px solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(88, 22, 71,1), rgba(0, 0, 0, 0)) 1 1 100%;
}

#time{
    width: 100px;
    height: 30px;    
    font-size: 28px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    position: relative;
    float: right;
    text-shadow:  0 0 3px rgb(88, 22, 72);
    margin: 12px;
}

#timeQuadro{
    width: 440px;
    height: 120px;
    border-radius: 30px;
    background-color: rgb(88, 22, 72);
    position: relative;  
    margin: 50px auto auto;  
}

.contador{
    width: 90px;
    height: 105px;
    background-color: rgb(255, 255, 255);
    float: left;
    left: 1px;
    position: relative;
    margin: 8px 4.5px;   
    color: rgb(88, 22, 72);
    font-weight: bold; 
    font-size: 12px;      
}

.con1{
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.con2{
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.cont {
    font-size: 48px;
}

.progress{
    height: 496px !important;
    width: 10px !important;
    background-color: rgb(88, 22, 72); 
    position: relative;
    margin: auto;
}

#progress{
    background-color: rgb(202, 180, 102);   
    box-shadow: 0px 0px 4px 4px rgb(202, 180, 102);
    /*overflow: initial;   
    position: absolute;
    bottom: 0;  
    height: 0;*/
}

.progress-bar-striped{
    background-image: linear-gradient(45deg, rgb(255 255 255 / 55%) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 55%) 50%, rgba(255, 255, 255, 55%) 75%, transparent 75%, transparent) !important;
}

.progress{
    background-color: rgb(88, 22, 72) !important;
}

.progress-bar-animated{
    animation-direction: reverse !important;
}
  
.marcador{
    height: 500px;
    width: 45px;
    left: 40px;
    position: relative;
    margin: 65px 100px;   
}

.check{
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: rgb(88, 22, 72); 
    position: absolute;
    z-index: 1;
}

.checked{
    box-shadow: 0px 0px 16px 6px rgb(88 22 72);
}

#c1, #c2, #c3, #c4, #c5, #c6{
    display: none;
    left: 81px;
    position: absolute;
    width: 306px;
}

#c1{
    top: -95px;
}

#c3{
    top: 90px;
}

#c4{
    top: 180px;
}

#c5{
    top: 270px;
}

#c6{
    top: 360px;
}
  
.c2{
    bottom: 360px;
}
  
.c3{
    bottom: 270px;
}
  
.c4{
    bottom: 180px;
}

.c5{
    bottom: 90px;
}

.c6{
    bottom: 0;
}

.check{
    cursor: pointer;
}

.check:hover{
    box-shadow: 0 0 3px 3px rgb(88 22 72);
}

#m-c2{
    top: 95px;
} 

#m-c3{
    top: 185px;
}

#m-c4{
    top: 275px;
} 

#m-c5{
    top: 365px;
} 

#m-c6{
    top: 455px;
}
  
.metaItens{
    text-align: right;
    position: absolute;
    right: 75px;
    font-size: 26px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-shadow: 0px 1px 3px #561546;
}

#avisoQuadro{
    min-width: 600px;
    font-family: 'Great Vibes';
    font-size: 48px;   
    line-height: 48px;
    position: relative; 
    margin: 60px auto;    
}


#personagens{
    background-color: rgb(88, 22, 72);
    padding-top: 50px;
}

.form{
    width: 304px;
    margin: auto;
    text-align: left;
    padding: 20px;
}

.form input, #registrar{
    border-radius: 2px;
    border: none;
    height: 30px;
    width: 260px;
    padding: 4px 10px;
}

#nome, #email, #senha{
    margin: 10px auto;
}

#registrar{
    position: relative;
    margin: 30px 80px;
    border-radius: 2px;
    width: 100px;
}

label{
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-weight: bold;
}

fieldset div{
    margin: auto;
    position: relative;
    padding: 20px;
    color:rgb(88, 22, 72);
    text-decoration: none;
    background-color: rgba(88, 22, 72, 0.72);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-size: 20px;
}

div a{
    color: rgb(255, 255, 255);
}


#login{
    margin: auto;
    position: relative;
    top: -7px; 
    left: 23px; 
}

.Erro{
    position: relative;
    background-color: rgb(255, 255, 255);
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
    height: auto;
    width: 360px;
    padding: 6px 2px;
    margin: auto;
    color: rgba(240, 0, 0, 0.815);
    font-weight: bold;
    top: -7px;
}

.Erro2{
    position: relative;
    height: auto;
    width: 250px;
    padding: 6px;
    margin: auto;
    color: rgba(240, 0, 0, 0.815);
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.205);

}

.button-registro{
    margin: auto;
    position: relative;
    background-color: rgba(88, 22, 72, 0.72);
    border-bottom-right-radius: 70px;
    border-top-left-radius: 70px;
    color: rgb(255, 255, 255);
    top: 172px;
    width: 150px;
    height: 40px;
    cursor: pointer;
    border: none;
}

.button-registro:hover{
    background-color: rgba(255, 255, 255, 0.72);
    border:2px groove rgb(88, 22, 72, 0.72);
    color: rgb(88, 22, 72);
    border-bottom-right-radius: 70px;
    border-top-left-radius: 70px;
    font-weight: bold;
}

.button-registro a:hover{
    color: rgb(88, 22, 72);
    font-weight: bold;
}

footer{
    /*background-color: rgb(100, 149, 237);*/
    background-image: linear-gradient(180deg, rgb(54 14 44), #561546);
    width: 100%;
    padding: 10px;
    /*position: relative;*/
    bottom: 0;
    justify-content: space-between;
    text-align: center;
    color: rgb(255, 255, 255);     
}

.redes-sociais{
    margin: 15px auto;
}

.redes-sociais a{
    padding: 8px;  
    text-decoration: none;
}

.RS-A{
    display: flex;
}

#carouselPersonagens{
    position: relative;
    width: 60%;
    height: 95%;
    min-width: 850px;
    margin: auto;    
}

.content-pj{
    height: 90%; 
    min-height: 960px;     
}

.personagem{
    position: relative;
    margin: auto;
}

.info-pj{
    width: 550px;
    height: 250px;
    top: 750px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.6);
    margin: auto; 
    box-shadow: 1px 0px 23px -3px rgb(188 162 123);
}

.info-pj h5{
    font-weight: bold;
    font-family: 'Great Vibes';
    font-size: 44px;
}

.info-pj p{
    text-justify: auto;
}

.navbar{
    margin: 5px 10px;
}

.input {
    background-color: rgba(149, 214, 240, 0);
    border: none;
    border-bottom: solid 2px rgb(255, 255, 255);
    margin: auto 20px auto 5px;
    padding: 0 2px 0 5px;
}

.input:focus{
    background-color: rgba(149, 214, 240, 0.164);
    border-bottom: solid 2px rgba(255, 255, 255, 0.459);
}

.label{
    color: rgb(255, 255, 255);
    position: relative;
    top: 6px;
}

.button{
    background-color: rgba(88, 22, 72, 0.72);
    border-bottom-right-radius: 70px;
    color: rgb(255, 255, 255);
    height: 90px;
    width: 131px;
    border-style: none;
    position: relative;
    left: 15px;
    font-size: 18px;
    font-weight: bold;
}

.button:hover{
    background-color: rgba(255, 255, 255, 0.72);
    color: rgb(88, 22, 72);
    font: bold;
    border: groove 2px rgb(88, 22, 72);
}

.logo{
    height: 400px;    
    min-width: fit-content;
    margin: auto;
    position: relative;
    right: 46px;    
}


/******* ESTILIZAÇÃO DA BARRA DE SCROLL *******/

body::-webkit-scrollbar {
    width: 16px;               /* width of the entire scrollbar */
}
  
body::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);        /* color of the tracking area */
}
  
body::-webkit-scrollbar-thumb {
    background-color: rgb(88, 22, 72);    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */    
}

/****** USUARIO CSS ******/
.content-head{
    width: 100%;
    padding: 10px;
    position: relative;    
    display: flex;
    background-image: linear-gradient(180deg, #561546, rgb(254 181 211));
    /*background-color: rgb(100, 149, 237);*/
}

#name-user{
    width: 169px;
    line-height: 45px;
}

#sair{
    width: 40px;
    height: 40px; 
    float: right;
    margin: 3px;
}

#sair img{
    width: 40px;
    cursor: pointer;    
}

.img-1{
    display: none;    
}

.img-0{
    display: block;    
}

#sair:hover .img-1 {
    display: block;
}

#sair:hover .img-0 {
    display: none;
}

.lang{
    position: relative;
    width: 50px;
    height: 50px;
    border: 1px solid;   
    float: right;
    margin: 0 5px;
}

.head-usuario{
    width: 75%;
    min-width: 900px;
    height: 120px;
    margin: auto auto 26px;
    position: relative;
    display: flex;
}

.logo-usuario{    
    width: 350px;
    height: 138px;
}

.logo-usuario2{    
    width: 450px;
    height: 148px;
    position: relative;
    margin: auto;
    top: 20px;
}

.logo-usuario img{
    width: 350px;
}

.logo-usuario2 img{
    width: 450px;
    position: relative;
    right: 27px;
    margin: 0 2px;
}

.content-usuario-head{
    width: 480px;
    height: 120px;  
    margin: 0 2px;  
    color: rgb(255, 255, 255);
    font-weight: bold;
}

.usuario{
    position: relative;
    height: 50px;
    width: 100%;   
    text-align: center;    
    display: flex;
}

.box-usuario{
    position: relative;
    width: 70%;
    text-align: right;
    float: right;
}

.moedas{
    width: 80%;
    margin: 3px 18px;
    display: flex;
}

.moedas img{
    width: 24px;
}

.notificacao{
    position: relative;
    width: 30%;
    top: 10px;
    text-align: center;
}

.notificacao a img{
    width: 20px;
}


/*.content{
    width: 100%;
    min-width: 950px;
    height: 945px;
    margin: 0px auto ;
    position: relative;
    border: 25px solid transparent;
    box-shadow:  0 0 26px rgb(255 203 225);
    background-image: url(../imagem/background.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}*/

.content-usuario{
    max-width: 80%;
    min-width: 1300px;
    height: 100%;
    padding: 80px 50px;
    position: relative;
    margin: auto;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.589);
}

.menu-usuario{
    width: 80px;
    float: left;
    position: relative;
    top: 110px;
}

.pj-usuario{
    width: 400px;
    height: 717px;
    position: relative;
    float: left;
    padding: 10px 50px;
    margin-left: 60px;
    margin-right: 40px;
    background-color: rgb(255, 255, 255);
}

.quadro{
    position: relative;
    width: 45%;
    min-width: 500px;
    max-width: 700px;
    height: 717px;    
    float: right;
    padding: 2px 10px;
    margin-right: 60px;    
}

.pj-usuario, .novidades, .episodios, #perfil, #guarda-roupa, .bazar, .menu-usuario{
    border-radius: 30px;    
    background-color: rgb(255, 255, 255);
} 

.celular, .config, .estatistica{
    width: auto;
    height: auto;   
    margin: 44px 0 44px ;
    padding: 10px 5px;
}

.celular a img{
    width: 50px;
    margin: auto 11px;
}

.estatistica a img{
    width: 75px;
}

.config a img{
    width: 70px;            
}

.novidades{
    width: 100%;
    height: 250px;
    position: relative;
}

.episodios{
    width: 100%;
    height: 200px;
    margin: 10px auto;
}

.pj-config{
    width: 100%;
    height: 204px;
    display: flex;
}

.episodios, .pj-config{
    margin: 10px auto;
}
  
.bazar{
    margin: auto 0 auto 12px;
    background-color: rgb(215, 215, 215);
}

.bazar img{
    width: 106px;
    margin: 68px 32%;
    opacity: 0.5;
}
  
#guarda-roupa, .bazar{
    width: 50%;
    height: 242px;
}


/******************************************************************************************/

.dBtnTop {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

.dBtnTop p{
    color: white;
    font-size: 35px; 
    text-shadow: 5px 5px 3px #561546;    
}

.dBtnTop a{
    width: 200px;
    height: 60px;
    position: relative;
    margin: auto;
}

#btnDemo {
    transition: 0.15s ease-in-out;
    margin-top: 10px;
    width: 200px;
    height: 50px;
    font-size: 15px; 
    border: 3px solid rgb(88, 22, 72, 0.80);
    background-color: rgba(90, 20, 72, 0.72);
    border-radius: 15px;
    color: white;
}

#btnDemo:hover {
    transition: 0.25s ease-in-out;
    /*transition: background-color 0.5s ease;*/
    width: 205px;
    height: 55px;
    font-size: 20px; 
    border: 3px solid rgb(88, 22, 72, 1);
    background-color: rgba(90, 20, 72, 1);
    color: white;
    text-shadow: 0px 0px 4px white;
    box-shadow: 0px 0px 3px 2px rgba(90, 20, 72, 1)
}

#btnDemo:active{
    transition: 0.15s ease-in-out;
    width: 203px;
    height: 53px;
    color: grey;
    border: 3px solid rgb(78, 12, 62, 0.9);
    background-color: rgba(80, 10, 62, 0.9);
    font-size: 17px; 
}

#btnRegistro {
    transition: 0.15s ease-in-out;
    margin-top: 10px;
    width: 200px;
    height: 50px;
    font-size: 15px; 
    border: 3px solid rgb(88, 22, 72, 0.80);
    background-color: rgba(90, 20, 72, 0.72);
    border-radius: 15px;
    color: white;
}

#btnRegistro:hover {
    transition: 0.25s ease-in-out;
    /*transition: background-color 0.5s ease;*/
    width: 205px;
    height: 55px;
    font-size: 20px; 
    border: 3px solid rgb(88, 22, 72, 1);
    background-color: rgba(90, 20, 72, 1);
    color: white;
    text-shadow: 0px 0px 4px white;
    box-shadow: 0px 0px 3px 2px rgba(90, 20, 72, 1)
}

#btnRegistro:active{
    transition: 0.15s ease-in-out;
    width: 203px;
    height: 53px;
    color: grey;
    border: 3px solid rgb(78, 12, 62, 0.9);
    background-color: rgba(80, 10, 62, 0.9);
    font-size: 17px; 
}

.carta{
    position: relative;
    top: 80px;
}

.carta img{
    position: relative;
    top: 20px;
}

.carta h3{
    font-size: 34px;
}

.carta p{
    font-size: 16px;
}

#text-carta{
    display: none;
    text-align: justify;    
}


#avisoLogRes{
    font-size: 14px;
}

  
/*************************** SOBRE ***************************/

.content-info{
    height: auto;
    width: auto;
    position: relative;
    margin: auto auto 50px;
    display: grid;
    justify-items: center;
    display: flex;
    justify-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.sobre-top{    
    position: relative;
    background-color: rgba(88, 22, 72, 0.72);
    height: 200px;
    padding: 5px;
    width: 800px;
    height: auto;
    margin: 50px auto 20px;
    border-bottom-right-radius: 70px;
    border-top-left-radius: 70px;
}

.sobre{
    font-weight: bold;
    padding: 8px 35px 18px;
}

#avisoLogRes{
    margin: auto;
    color: rgb(255 255 255);
    font-weight: bolder;
    font-size: 16px;
    top: 8px;
    position: relative;  
}

#avisoLogRes h3{
    margin: 5px 40px 20px;
    font-weight: 400;
    font-size: 28px;
}

.box-sobre{
    display: grid;
    position: relative;
    width: 100%;
    height: 660px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
}

.info{
    height: 600px;
    width: 200px;
    margin: 15px 10px;    
    background-color: rgb(255, 255, 255); 
    cursor: pointer;   
}

.info:hover {
    transition: 0.10s ease-in-out;
    width: 205px;
    height: 605px;
    text-shadow: 0px 0px 4px white;
    box-shadow: 0px 0px 3px 2px rgba(90, 20, 72, 1);
    border: 5px groove rgb(88, 22, 72, 0.72); 
}

.ibord{
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
}

.ibord2{
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.info h3{
    padding: 15px;
    position: relative;
    top: 400px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 rgb(0, 0, 0);
}

#i0, #i1, #i2, #i3{
    background-repeat: no-repeat;    
    background-size: cover;
}

#i0 p, #i1 p, #i2 p, #i3 p{
    font-size: 14px;
    position: relative;
    padding: 15px;
    color: (86, 21, 70);   
    font-weight: bold;
    top: 240px;
    display: none;
}

#i0{
    background-image: url(../image/Escola.png);
}

#i1{
    background-image: url(../image/Historia.png);
}

#i2{
    background-image: url(../image/Jogo.png);
}

#i3{
    background-image: url(../image/Equipe.png);
}

/*************************** ERRO TELA ***************************/

.avisoLoginRegisto{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
}

.content-avisoLR{
    width: 1000px;
    height: 800px;
    position: relative;
    margin: 50px auto;
}

.avisoChibi{
    width: 550px;
    float: right;
    transform: rotateY(180deg)
}

.avisoLR p, .avisoLR h2{
   color: rgb(2, 2, 2);
   text-align: center;
   top: 30px;
   position: relative;
}

.avisoLR p{
    font-size: 16px;
}

.avisoLR{
    height: 400px;
    width: 600px;
    background-color: rgb(255, 255, 255, 70%);
    outline: #a49a31 double 4px;
    outline-offset: 25px;
    border-radius: 10%;
    position: absolute;
    top: 100px;
}

.content-avisoLR .content-buttonBack{
    position: relative;
    top: 550px;
    left: -70px;
}

.avisoLR .redes-sociais{
    margin: auto;
    position: relative;
    top: 80px;
    left: 140px;
}

.redes-sociais img{
    width: 60px;
}

.redes-sociais p{
    font-weight: bold;
    color: rgb(255, 207, 228);
    text-shadow: -2px 0px 1px rgb(254, 172, 205);
}

.container { margin-top: 10px; }

.progress-bar-vertical {
  width: 20px;
  min-height: 100px;
  display: flex;
  align-items: flex-end;
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}


/*************************** Registro/Login ***************************/
.content-registro{
    background-image: url();
}