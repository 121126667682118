/************************* Telas com Resolução Mobile *************************/
@media screen and (min-width: 320px) and (max-width: 519px){

    /******************* INDEX/SOBRE/EMBREVE/DEMO *******************/    
    .logo{
        right: 4px;
    }

    #avisoLogRes h3{
        margin: 5px 20px 15px;
        font-weight: 400;
    }    

    #avisoQuadro{
        min-width: 200px;        
        font-size: 38px;        
        margin: 40px auto;
    }

    #timeQuadro{
        width: 260px;
        height: 101px;       
    }

    .contador{
        height: 85px;       
        font-size: 10px;
    }
    
    .cont{
        font-size: 40px;
    }

    .marcador{
        margin: 65px 24px;       
    }

    #carouselPersonagens{
        min-width: 0;
        width: 285px;
    }

    .carousel-item img{
        width: 300px;
    }

    .info-pj{
        top: 700px;
    }

    .d-md-block{
        display: block !important;
    }    

    .carta{
        top: 60px;
    }

    .carta p{
        position: relative;
        top: 25px;
    }

    .carta img{
        top: 17px;
    }

    #text-carta{
        display: block;
    }

    .box-sobre{
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .info{
        border-radius: 40px;   
        height: 150px;       
    }

    .info:hover{
        height: 150px;
    }

    .info h3{
        top: 55px;
    }

    .login, .cad, .sobreTop, .inicio{
       display: none;
    }
    
    #navbar-dropdown{
       display: block;
       font-size: 30px;
       padding: 6px 20px !important;
    }

    .dropdown-menu a{
        padding: 10px 20px !important;
    }

    .dropdown-menu{    
        border: 0;
        border-radius: 0;  
        transform: translate(0px, 56px) !important;      
    }

    .time-content2{
        display: block;
    }

    .time-content1{
        display: none;
    }

    .content-avisoLR{
        width: 100%;
        height: 400px;
    }

    .avisoChibi{
        width: 190px;
        top: 35px; 
        position: relative; 
        right: -25px 
    }

    .avisoLR img{
        top: 16px;
        position: relative;
    }

    .avisoLR{
        height: 230px;
        outline-offset: 15px;        
        margin: 0px 25px;
        top: 50px;
    }

    .content-buttonBack{
        position: relative;
        top: 315px !important;
    }

    #c1, #c2, #c3, #c4, #c5, #c6{
        width: 130px;
    }

    #c1{
        top: -25px;
    }

    #c2{
        top: 70px;
    }
    
    #c3{
        top: 160px;
    }
    
    #c4{
        top: 250px;
    }
    
    #c5{
        top: 340px;
    }
    
    #c6{
        top: 430px;
    }

    #i0 p, #i1 p, #i2 p, #i3 p {
        top: 15px;
    }


    /*************************************************************************/
    /** De 320 a 360 --- 374 **/
    @media screen and (min-width: 320px) and (max-width: 374px){
        .marcador{
            left: 40px;
        }
    
        .metaItens{
            right: 60px;
        } 
        
        #avisoLogRes h3{
            font-size: 22px;
        }  

        .contador{
            width: 50px;
        }  

        .info-pj{
            width: 190px;
        }

        .carta img{
            width: 278px;
        }

        .sobre-top{
            width: 274px;
        }

        .logo{
            height: 120px;    
        }

        .info{
            width: 260px;    
        }

        .info:hover{
            width: 260px;       
        }

        .avisoLR .redes-sociais{
            left: 5px;
            top: -18px;
        }

        .avisoLR img{
            height: 30px;
            width: 30px;            
        }

        .content-buttonBack{
            left: 80px !important;
        }

        .avisoLR{
            width: 230px;
        }

        .redes-sociais p{
            font-size: 16px;
        }  
        
    }

    
    /** De 375 a 519 **/
    @media screen and (min-width: 375px) and (max-width: 519px){
        .marcador{
            left: 60px;
            margin: 65px 24px;       
        }
    
        .metaItens{
            right: 60px;
        } 
        
        .content-buttonBack{
            left: 35px !important;
        }

        .avisoLR{
            width: 260px;
        }

        .redes-sociais p{
            font-size: 14px;
        }

        .avisoLR img{
            height: 40px;
            width: 40px;
        }

        .avisoLR .redes-sociais{
            left: 30px;
            top: -5px;
        }

        .logo{
            height: 140px;    
        }

        .carta img{
            width: 330px;
        }

        .info{
            width: 330px;
        }

        .info:hover{
            width: 330px;       
        }

        .sobre-top{
            width: 310px;
        }

        #timeQuadro{
            width: 280px;                 
        }

        .contador{
            width: 55px;
        }  
        
        .info-pj{
            width: 220px;
        } 
    }

    
    /* De 390 a 393 **/
    @media screen and (min-width: 390px) and (max-width: 393px){
        .logo{
            height: 145px;    
        }

        .sobre-top{
            width: 320px;
        }  
        
        .content-buttonBack{
            left: 45px !important;
        }
    }    


    /** De 412 a 520 **/
    @media screen and (min-width: 412px) and (max-width: 519px){
        .logo{
            height: 155px;    
        }

        .sobre-top{
            width: 335px;
        }   
        
        .content-buttonBack{
            left: 5px !important;
        }
    } 


    /** De 390 a 520 **/
    @media screen and (min-width: 390px) and (max-width: 519px){
        .carta img{
            width: 370px;
        }

        .info{
            width: 350px;
        }

        .info:hover{
            width: 350px;
        }

        .avisoLR img{
            height: 50px;
            width: 50px;
        }

        .redes-sociais p{
            font-size: 18px;
        }       

        .avisoLR{
            width: 290px;
        }

        .avisoLR .redes-sociais{
            left: 30px;
            top: 3px;
        }  
        
        #carouselPersonagens{
            width: 350px;
        }

        .info-pj{
            width: 250px;
        }

        .content-avisoLR h2, .content-avisoLR p{
            padding: 0px 10px;
        }

        .content-buttonBack{
            left: 35px !important;
        }
    }
    
}


/************************* Telas com Resolução Para Tablet *************************/
/** De 520 a 749 **/
@media screen and (min-width: 520px) and (max-width: 749px){

    /******************* INDEX/SOBRE/EMBREVE *******************/    
    .logo{
        height: 200px;    
        right: 15px;
    }

    .sobre-top{
        width: 485px;
    }   

    .marcador{
        left: 80px;
        margin: 65px 70px;       
    }

    #avisoQuadro{
        min-width: 445px;
    }

    #carouselPersonagens{
        min-width: 0;
        width: 490px;
    }

    .info-pj{
        width: 360px;
        top: 700px;
    }

    .d-md-block{
        display: block !important;
    }  
    
    .carta p{
        position: relative;
        top: 20px;
    }

    .carta img{
        top: 30px;
        width: 470px;
    }

    #text-carta{
        display: block;
        top: 50px;
        padding: 15px;
    }
   
    .avisoLR .redes-sociais{
        left: 75px;
        top: 10px;        
    }

    .redes-sociais p{
        padding: 0 !important;
    }

    .avisoLR img{
        height: 50px;
        width: 50px;   
        top: 10px;
        position: relative;         
    }

    .content-buttonBack{
        left: 107px !important;
        top: 400px !important;
    }

    .avisoLR{
        width: 400px;
        height: 300px;
        outline-offset: 15px;        
        margin: 0px 35px;
        top: 60px;
    }

    .redes-sociais p{
        font-size: 16px;        
    }  

    .content-avisoLR{
        width: 100%;
        height: 400px;        
    }

    .content-avisoLR h2, .content-avisoLR p{
        padding: 6px 80px;
    }

    .avisoChibi{
        width: 335px;
        top: 35px; 
        position: relative; 
        right: -45px 
    }  

    .carousel-item img{
        width: 300px;
    }

    .box-sobre {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .info {
        width: 450px;
        height: 150px;
        border-radius: 40px;
    }

    .info:hover {
        width: 450px;  
        height: 150px;      
    }

    .info h3 {
        top: 55px;
    }

    #c1, #c2, #c3, #c4, #c5, #c6{
        width: 185px;
    }

    #c1{
        top: -45px;
    }

    #c2{
        top: 50px;
    }
    
    #c3{
        top: 135px;
    }
    
    #c4{
        top: 230px;
    }
    
    #c5{
        top: 320px;
    }
    
    #c6{
        top: 410px;
    }

    #i0 p, #i1 p, #i2 p, #i3 p {
        top: 15px;
    }
}


/** De 750 a 860**/
@media screen and (min-width: 750px) and (max-width: 860px){

    /******************* INDEX/SOBRE/EMBREVE *******************/    
    .logo{
        height: 280px;    
        right: 15px;
    }

    .sobre-top{
        width: 700px;
    }   

    .marcador{
        left: 100px;
        margin: 65px 70px;       
    }

    #carouselPersonagens{
        min-width: 0;
        width: 650px;
    }

    .info-pj{
        width: 400px;
        top: 700px;
    }

    .d-md-block{
        display: block !important;
    }  
    
    .carta p{
        position: relative;
        top: 20px;
    }

    .carta img{
        top: 50px;
        width: 550px;
    }
   
    .avisoLR .redes-sociais{
        left: 75px;
        top: 10px;        
    }

    .redes-sociais p{
        padding: 0 !important;
    }

    .avisoLR img{
        height: 50px;
        width: 50px;   
        top: 10px;
        position: relative;         
    }

    .content-buttonBack{
        left: 0 !important;
        top: 400px !important;
    }

    .avisoLR{
        width: 500px;
        height: 300px;
        outline-offset: 15px;        
        margin: 0px 80px;
        top: 60px;
    }

    .redes-sociais p{
        font-size: 16px;        
    }  

    .content-avisoLR{
        width: 100%;
        height: 400px;        
    }

    .content-avisoLR h2, .content-avisoLR p{
        padding: 6px 80px;
    }

    .avisoChibi{
        width: 335px;
        top: 35px; 
        position: relative; 
        right: -25px 
    }  

    #text-carta{
        top: 40px;
        display: block;
        padding: 10px;
    }
}


/************************* Outras Configurações de Resolução de Tela *************************/
